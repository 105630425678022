import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Stats from "../components/DowntownFinances/Stats"
import Advantages from "../components/DowntownFinances/Advantages"
import Products from "../components/DowntownFinances/Products"
import Investments from "../components/DowntownFinances/Investments"
import Spacer from "../components/DowntownFinances/Spacer"
import FooterDF from "../components/DowntownFinances/Footer_DF"
import TopBannerDF from "../components/DowntownFinances/TopBanner_DF"
import BlueBackButton from "../components/BlueBackButton"
import FakePageDisclaimer from "../components/FakePageDisclaimer"

export default function downtownFinances({data}){

    const topBanner = data.allDowntownFinancesJson.nodes[0].TopBanner;
    const stats = data.allDowntownFinancesJson.nodes[0].StatsElement;
    const advantages = data.allDowntownFinancesJson.nodes[0].Advantages;
    const products = data.allDowntownFinancesJson.nodes[0].Products;
    const investments = data.allDowntownFinancesJson.nodes[0].Investments;

    return(
      <div>
            <Helmet>
                <meta name="robots" content="noindex" charSet="utf-8"/>
                <title>Website Design Beispiel</title>
                <html lang="de"/>
            </Helmet>
          <BlueBackButton/>
          <TopBannerDF data={topBanner}/>
          <Spacer id="content" height="55"/>
          <Stats data={stats}/>
          <Spacer height="120"/>
          <Advantages data={advantages}/>
          <Spacer height="160"/>
          <Products data={products}/>
          <Spacer height="160"/>
          <Investments data={investments}/>
          <Spacer/>
          <FooterDF/>
          <FakePageDisclaimer data={data.allGlobalJson.nodes[0].Disclaimer}/>
      </div>
    )
}

export const query = graphql`
{
    allDowntownFinancesJson {
        nodes {
            TopBanner {
                title
                src {
                    childImageSharp{
                        gatsbyImageData(
                            quality: 70
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                alt
                navButtons {
                    text
                }
            }
          StatsElement {
            title
                stats   {
                    text1
                    text2
                    value1
                    value2
                }
            }
            Advantages {
                title
                    Subitems   {
                        text
                        title
                    }
                }
            Products {
                title
                Subitems {
                    title
                    text
                    src{
                        childImageSharp{
                            gatsbyImageData(
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                      }
                    alt
                }
            }
            Investments {
                text
                title
            }
        }
    }
    allGlobalJson {
        nodes {
            Disclaimer
        }
    }
}
`