import React from "react"
import styled from "styled-components"
import ShakingButton from "./ShakingButton"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Container = styled.div`
position:relative;
width:75%;
margin: 0 auto;

& h2:nth-child(1){
    text-align:center;
    font-family:Darker Grotesque;
    font-weight:400;
    font-size:28px;

    @media(min-width:601px){
        font-size:36px;
    }
}

@media(min-width:993px){
    width:90%;
}
`

const SubitemContainer = styled.div`
position:relative;
width:100%;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-ms-flex-pack:distribute;
    justify-content:space-around;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
`

const Subitem = styled.div`
position:relative;
width:90%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
margin:1em 0;


& h3{
    position:relative;
    text-align:center;
    line-height: 20px;
    font-family:Montserrat;
    font-weight:bold;
    font-size:18px;

    &:after{
        content: '';
        position: absolute;
        left: 0; 
        bottom: -20px;
        height: 3px;
        width:100%;
        background-color: #6AA4C5;
        border-radius:200px;
    }
}

& p{
    font-size:20px;
    font-family:Darker Grotesque;
}

& p:nth-child(2) {
    margin-top: 50px;
}

& p:nth-child(3){
    position:relative;
    text-align:right;
    font-size:20px;
    color:#6AA4C5;
    -webkit-transition:all 0.3s;
    -o-transition:all 0.3s;
    transition:all 0.3s;

    &:after{
        content: '';
        position: absolute;
        right: 0;
        bottom:2px;
        height: 1px;
        width:102px;
        background-color: transparent;
        border-radius:200px;

        -webkit-transition:0.3s all;

        -o-transition:0.3s all;

        transition:0.3s all;
    }
}

& p:nth-child(3):hover{
    color:#23668B;

    &:after {
        background-color:#23668B;
    }
}

@media(min-width:601px){
    & h3{
        font-size:20px;
    }
    
    & p{
        font-size:22px;
    }
    
    & p:nth-child(3){
        font-size:22px;
    }
}

@media(min-width:993px){
    width:60%;
}

@media(min-width:1100px){
    width:25%;
}
`

const ImageContainer = styled.div`
width:100%;

display:-webkit-box;
display:-ms-flexbox;

display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
`

const StyledGatbsyImage = styled(GatsbyImage)`
width:100%;

& img{
    width:100%;
    height:auto;
}
`


export default function WebsiteSubitems(props){
    return(
        <Container id={props.id}>
            <h2>{props.data.title}</h2>
            <SubitemContainer>
                {
                    props.data.Subitems.map((item, i) => (
                        <Subitem key={i}>
                            <ImageContainer>
                                <StyledGatbsyImage image={getImage(item.src)} alt={item.title}/>
                            </ImageContainer>
                            <div>
                                <h3>{item.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: item.text }}/>
                            </div>
                            <ShakingButton>mehr Erfahren</ShakingButton>
                        </Subitem>
                    ))
                }
            </SubitemContainer>
        </Container>
    )
}