import React from "react"
import styled from "styled-components"

import Phone from "../../../data/icons/downtownFinances/phone.svg"
import Mail from "../../../data/icons/downtownFinances/mail_breit.svg"
import ShakingButton from "./ShakingButton"

const Container = styled.div`
position:relative;
width:100%;
overflow-y: hidden;
`

const Contact = styled.div`
width:100%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-flow:column wrap;
        flex-flow:column wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:space-evenly;
-ms-flex-pack:space-evenly;
        justify-content:space-evenly;

padding: 25px 0;

@media(min-width:601px){
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-flow:row wrap;
            flex-flow:row wrap;
}

background-color:#E0EAEB;

& >div {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;

    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-flow:row wrap;
        flex-flow:row wrap;
    -webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;

    @media(min-width:601px){
        -webkit-box-pack:flex-start;
        -ms-flex-pack:flex-start;
        justify-content:flex-start;
    }

    & p{
        margin-left: 15px;
        color:#4E5D73;
    }

    & p:nth-child(2n-1){
        font-size:15px;
        font-family:Montserrat;
        font-weight:400;

        @media(min-width:601px){
            font-size:18px;
        }
    }

    & p:nth-child(2n){
        font-size:16px;
        font-family:Montserrat;
        font-weight:bold;

        @media(min-width:601px){
            font-size:20px;
        }
    }
}
`

const Footnotes = styled.div`
width:100%;
padding-bottom:1em;
background-color:#4E5D73;

@media (min-width:601px) {
    padding-bottom:0;
}

& p{
    color:#FFFFFF;
    text-align:center;
    text-decoration:none;
    font-size:16px;
    font-family:Montserrat;
    font-weight:400;

    @media(min-width:601px){
        font-size:18px;
    }
  }
`

export default function Footer_DF(){
    
    return(
        <Container>
            <Contact>
                <div>
                    <Mail/>
                    <div>
                        <p>schreiben sie uns eine <br/> Email</p>
                        <p><b>support@mustermail.com</b></p>
                    </div>
                </div>
                <div>
                    <Phone/>
                    <div>
                        <p>unser Support ist <br/> immer für Sie da</p>
                        <p><b>01632 960017</b></p>
                    </div>
                </div>
            </Contact>
            <Footnotes className={"s1-12 row"}>
                <div className="l1-4 m1-12 s1-12">
                  <ShakingButton>Impressum</ShakingButton>
                </div>
                <p className="l5-8 m1-12 s1-12">© Kudlik & Pfeifer GbR</p>
                <div className="l9-12 m1-12 s1-12">
                  <ShakingButton>Datenschutz</ShakingButton>
                </div>
            </Footnotes>
        </Container>
    )
}