import React from "react"
import styled from "styled-components"


const Container = styled.div`
position:relative;
width:100%;

& h2 {
    text-align:center;
    font-family:Darker Grotesque;
    font-weight:400;
    font-size:28px;

    @media(min-width:601px){
        font-size:36px;
    }
}
`

const StatContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-flow: row wrap;
        flex-flow: row wrap;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
width:100%;
`

const StatSubContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-flow: row wrap;
        flex-flow: row wrap;
-webkit-box-pack:space-evenly;
-ms-flex-pack:space-evenly;
        justify-content:space-evenly;

@media(max-width:600px){
    width:80%;
}
`

const StatElement = styled.div`
position:relative;
width:100%;
margin:0 25px;

@media(min-width:601px){
    width:300px;
}

& p:nth-child(2){
    font-family:Darker Grotesque;
    font-size:20px;
    line-height:0px;
    text-align:right;
    margin-right:10px;

    @media(min-width:601px){
        font-size:30px;
        margin-right:20px;
    }
}
`

const StatText = styled.p`
position:relative;
font-family:Hind Guntur;
font-weight:700;
font-size:28px;

&:after{
    content: '';
    position: absolute;
    left: 0; 
    bottom: -6px;
    height: 3px;
    width:100%;
    background-color: #6AA4C5;
    border-radius:200px;
}

@media(min-width:601px){
    font-size:36px;
}
`

export default function Stats(props){
    return(
        <Container id={props.id}>
            <h2>{props.data.title}</h2>
            <StatContainer>
                {
                    props.data.stats.map((item, i) => (
                        <StatSubContainer key={i}>
                            <StatElement>
                                <StatText>{item.value1}</StatText>
                                <p>{item.text1}</p>
                            </StatElement>
                            <StatElement>
                                <StatText>{item.value2}</StatText>
                                <p>{item.text2}</p>
                            </StatElement>
                        </StatSubContainer>
                    ))
                }
            </StatContainer>
        </Container>
    )
}