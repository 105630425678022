import React from "react"
import styled from "styled-components"
import DownArrow from "../../../data/icons/downtownFinances/DownArrow.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import NavBarDF from "./NavBar_DF"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Container = styled.div`
position:relative;
width:100%;
height:100vh;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;

& h1 {
    padding:0 15px;
    padding-bottom:15px;
    border:3px solid #FFF;
    color:#FFF;
    text-align:center;

    font-family:Darker Grotesque;
    font-weight:500;
    font-size:10vw;

    @media(min-width:750px){
        font-size:74px;
        padding:0 25px;
        padding-bottom:15px;
    }
}

overflow-y:hidden;
`

const DownArrowContainer = styled(AnchorLink)`
position:absolute;
bottom:10px;
margin:0 auto;

&:hover {
    cursor:pointer;
}
`

const BackgroundImage = styled(GatsbyImage)`
position:absolute !important;
top:0 !important;
left:0 !important;
width:100%;
height:100%;

z-index:-1;
`

export default function TopBanner_DF(props){
    return(
        <Container>
            <BackgroundImage loading="eager" image={getImage(props.data.src)} alt="Background Image"/>
            <h1>{props.data.title}</h1>
            <DownArrowContainer to={"/downtownFinances/#content"}>
                <DownArrow/>
            </DownArrowContainer>
            <NavBarDF data={props.data}/>
        </Container>
    )
}