import React from "react"
import styled from "styled-components"
import DiverseInvestments from "../../../data/icons/downtownFinances/diverseInvestments.svg"

const Container = styled.div`
position: relative;
width: 80%;
margin: 0 auto;

& h2{
    text-align: center;
    font-family:Darker Grotesque;
    font-weight:400;
    font-size:28px;

    @media(min-width:601px){
        font-size:36px;
    }
}
`

const SubContainer = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: flex;
width: 100%;

-webkit-box-align:center;

-ms-flex-align:center;

        align-items:center;
-webkit-box-pack: space-evenly;
-ms-flex-pack: space-evenly;
        justify-content: space-evenly;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-flow: row wrap;
        flex-flow: row wrap;

& svg {
    width: 580px;
}

& p {
    width:540px;
    margin: 0 20px;
    font-family:Darker Grotesque;
    font-size:20px;

    @media(min-width:601px){
        font-size:22px;
    }
}
`

export default function Investments(props){
    return(
        <Container>
            <h2>{props.data.title}</h2>
            <SubContainer>
                <DiverseInvestments/>
                <p dangerouslySetInnerHTML={{ __html: props.data.text }}/>
            </SubContainer>
        </Container>
    )
}