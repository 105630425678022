import React from "react"
import styled from "styled-components"
import NavIcon from "../../../data/icons/downtownFinances/NavIcon.svg"
import ShakingButton from "./ShakingButton"

const Container = styled.div`
position:absolute;
width:100%;
top:0;
left:0;
`

const NavBig = styled.div`
position:absolute;
top:0px;
right:0px;

display:none;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;

& svg {
    width:35px;
    height:35px;
    margin-top:26px;
    margin-right:12px;
}

& div{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    gap:10px;

    margin-top:25px;
    margin-right:35px;
    
    & p {
        margin:0;
        font-family:Darker Grotesque;
        font-size:24px;
        color:#FFF;
    }
}

@media(min-width:993px){
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
}
`

const NavSmall = styled.div`
position:fixed;
width:100%;
height:55px;
z-index:5;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;
-webkit-box-pack: end;
-ms-flex-pack: end;
        justify-content: flex-end;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;

& svg {
    height:75%;
    margin-right:minmax(0px,50px);
}

& svg:hover {
    cursor:pointer;
}

& p {
    font-family:Darker Grotesque;
    font-size:8vw;
    color:#FFF;
    margin-right:15px;
    padding-bottom:6px;

    @media(min-width:401px){
        font-size:32px;
    }
}

@media(min-width:993px){
    display:none;
}
`

const NavScroll = styled.div`
position:fixed;
background-color:#333;
width:100%;
height:55px;
z-index:5;
display:${props => props.atTop?("none"):("-webkit-box")};
display:${props => props.atTop?("none"):("-ms-flexbox")};
display:${props => props.atTop?("none"):("flex")};
-webkit-transition:all 0.5;
-o-transition:all 0.5;
transition:all 0.5;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;
-webkit-box-pack: end;
-ms-flex-pack: end;
        justify-content: flex-end;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;

& svg {
    height:75%;
    margin-right:minmax(0px,50px);
}

& svg:hover {
    cursor:pointer;
}

& p {
    font-family:Darker Grotesque;
    font-size:32px;
    color:#FFF;
    margin-right:15px;
    padding-bottom:6px;
}
`

const Dropdown = styled.div`
position:fixed;
top:0px;
right:0px;
height:100%;
-webkit-transform: translate(${props => props.showDropdown?("0"):("100%")});
-ms-transform: translate(${props => props.showDropdown?("0"):("100%")});
transform: translate(${props => props.showDropdown?("0"):("100%")});
background-color:#11111190;
-webkit-transition:-webkit-transform 1s;
transition:-webkit-transform 1s;
-o-transition:transform 1s;
transition:transform 1s;
transition:transform 1s, -webkit-transform 1s;
z-index:4;

& div{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    gap:15px;

    margin-top:75px;
    margin-right:20px;
    
    & p {
        margin:0;
        padding:15px 150px 15px 40px;
        font-family:Darker Grotesque;
        font-size:24px;
        color:#FFF;
        -webkit-transition:background-color 0.9s;
        -o-transition:background-color 0.9s;
        transition:background-color 0.9s;
    }

    & p:hover {
        background-color:#00000099;
    }
}

@media(min-width:993px){
    -webkit-transform: translate(${props => props.showDropdown?(props.atTop?("100%"):("0")):("100%")});
    -ms-transform: translate(${props => props.showDropdown?(props.atTop?("100%"):("0")):("100%")});
    transform: translate(${props => props.showDropdown?(props.atTop?("100%"):("0")):("100%")});
}
`

const ClosingDiv = styled.div`
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
z-index:3;
display:${props => props.showDropdown?("block"):("none")};
`

class NavBar_DF extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            atTop: true,
            showDropdown: false
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.checkScrollHeight)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.checkScrollHeight)
    }

    checkScrollHeight = () => {
        if(document.documentElement.scrollTop > window.innerHeight-10){
            this.setState({
                atTop: false
            })
        }
        else{
            this.setState({
                atTop: true
            })
        }
    }

    toggleDropdown(){
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    render(){
        return(
            <Container>
                <NavBig>
                    <NavIcon/>
                    <div>
                        {
                            this.props.data.navButtons.map((item, i) => (
                                <ShakingButton key={i}>{item.text}</ShakingButton>
                            ))
                        }
                    </div>
                </NavBig>

                <NavSmall>
                    <NavIcon onClick={this.toggleDropdown.bind(this)}/>
                </NavSmall>

                <NavScroll atTop={this.state.atTop}>
                    <p>Downtown Finances</p>
                    <NavIcon onClick={this.toggleDropdown.bind(this)}/>
                </NavScroll>

                <Dropdown showDropdown={this.state.showDropdown} atTop={this.state.atTop}>
                    <div>
                        {
                            this.props.data.navButtons.map((item, i) => (
                                <ShakingButton key={i}>{item.text}</ShakingButton>
                            ))
                        }
                    </div>
                </Dropdown>
                <ClosingDiv showDropdown={this.state.showDropdown} onClick={this.toggleDropdown.bind(this)}/>
            </Container>
        )
    }
}

export default NavBar_DF;